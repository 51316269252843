import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import {EmailLink} from "../ContactData";
import {faComments} from '@fortawesome/free-regular-svg-icons';
import './ContactForm.scss';

class ContactForm extends Component {

  state = this.getInitialState();

  getInitialState() {
    return {
      email : undefined,
      phone : undefined,
      message : undefined,
      isSending : false,
      isError : false,
      isSent : false
    };
  }

  send() {
    if (!this.validate()) {
      // TODO: Message Box
      return;
    }
    this.setState({isSending : true, isError : false});

    const payload = JSON.stringify({'email' : this.state.email, 'phone' : this.state.phone, 'message' : this.state.message});
    console.log('sending: ' + payload);
    fetch("contact.php?test=1", {
      method : 'POST',
      mode : 'cors',
      cache : 'no-cache',
      headers : {'Content-Type' : 'application/json'},
      redirect : 'follow',
      referrer : 'no-referrer',
      body : payload,
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        this.reset();
        this.setState({isSent : true});
      } else {
        this.reset();
        this.setState({isError : true});
      }
    });
  }

  reset() {
    this.setState(this.getInitialState());
  }

  validate() {
    // TODO
    return true;
  }

  render() {
    const isSendingSpinner = <span className="spinner-border text-secondary" role="status" aria-hidden="true"/>;
    const sendButton = <button type="button" className="btn btn-primary" onClick={() => this.send()} disabled={this.state.isSending}>Send</button>;
    const closeButton = <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>;
    const formPanel =
      <>
        <p>
          Contact me directly? Give me a call (<EmailLink/>) or
        </p>
        <p>
          Want me to get back to you? Fill in the form below and I'll get back to you as soon as possible.
        </p>
        <form>
          <div className="form-group row">
            <label htmlFor="contact-email" className="col-sm-2 col-form-label">E-mail:</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="contact-email" placeholder="Please enter e-mail address"
                     disabled={this.state.isSending} onChange={(e) => this.setState({email : e.target.value})}/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="contact-phone" className="col-sm-2 col-form-label">Telephone:</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="contact-phone" placeholder="Please enter telephone number"
                     disabled={this.state.isSending} onChange={(e) => this.setState({phone : e.target.value})} value={this.state.phone}/>
            </div>
          </div>
          <div className="form-group">
                  <textarea className="form-control" id="contact-message" placeholder="Please enter your message"
                            disabled={this.state.isSending} onChange={(e) => this.setState({message : e.target.value})} value={this.state.message}/>
          </div>
        </form>
      </>;
    const errorPanel =
      <div className="alert alert-danger" role="alert">
        I'm terribly sorry: I was unable to send your message, so please try again.
        <br/>
        If this problem persists, please contact me by e-mail (<EmailLink/>) or telehpone.
      </div>;
    const successPanel =
      <div className="success">
        <FontAwesomeIcon icon={faCheckCircle} className="fa-fw fa-pull-left"/>
        <div>Thank you for your interest, your contact request has been sent. I'll get in touch as soon as possible.</div>
      </div>;

    return (
      <div className="modal fade" id="contactForm" tabIndex="-1" role="dialog" aria-labelledby="contactFormTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="contactFormTitle">
                <FontAwesomeIcon icon={faComments}/>
                &nbsp;Contact
              </h5>
            </div>
            <div className="modal-body">
              {this.state.isError && errorPanel}
              {this.state.isSent ? successPanel : formPanel}
            </div>
            <div className="modal-footer">
              {this.state.isSending ? isSendingSpinner : (this.state.isSent ? closeButton : sendButton)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
