import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

const details = {
  location : "Arnhem, Netherlands",
  email : "info@reinkrul.nl"
};

export class EmailLink extends Component {
  link() {
    return <a href={"mailto:" + details.email}>{details.email}</a>;
  }

  icon() {
    return <FontAwesomeIcon icon={faEnvelope} className="fa-fw"/>
  }

  render() {
    return <span className="fa-fw">
      {this.icon()}
      {this.link()}
    </span>;
  }
}

export class EmailListLink extends EmailLink {
  render() {
    return <>
      <span className="fa-li" style={{paddingTop: "2px"}}>{this.icon()}</span>
      {this.link()}
    </>;
  }
}

export default details;