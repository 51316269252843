import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {EmailListLink} from "../ContactData";
import './HomePage.scss';
import profilePicture from '../../images/profile-picture-large.jpg';

class HomePage extends Component {
  render() {
    return (
      <div className="container">
        <div className="page" id="start">
          <div className="header">
            <h1 className="display-4">Open Source Software Engineer | Architect</h1>
          </div>

          <div className="container">
            <div className="row">
              <img src={profilePicture} alt="Profile" className="profile-picture"/>
              <div className="col-sm">
                <p>
                  I am Rein Krul. I believe in free-flowing information between organisations to improve process quality, speed and security to build a better world.
                </p>
                <p>
                  The way I do this in the health care sector is by designing and implementing self-sovereign identity systems that use W3C Verifiable Credentials, DIDs, personal and organisational credential wallets, HL7 FHIR and <a href="https://nuts.nl/" target="_blank">Nuts</a>.
                </p>
                <p>
                  Most of my time is spent designing and implementing open source software.
                </p>
              </div>
              <ul className="fa-ul col-sm-3">
                <li className="my-2"><span className="fa-li"><FontAwesomeIcon icon="map-marker-alt"/></span>Arnhem, Netherlands</li>
                <li className="my-2"><EmailListLink/></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
