import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import {EmailLink} from "../ContactData";
import {faComments} from '@fortawesome/free-regular-svg-icons';
import './ContactForm.scss';

class AboutMe extends Component {

  render() {

    return (
      <div className="modal fade" id="aboutMe" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">How did I get here?</h5>
            </div>
            <div className="modal-body">
              <p>
                <strong>How did I get here?</strong> At the age of 10 (it was the year of 1999) I wrote my first program on my dad's Intel 368 in QuickBASIC &bull;
                In 2003 I started <a href="/img/rynusrein.tt-2004.png" rel="noopener noreferrer" target="_blank">3D game programming in Visual Basic 6 and DirectX</a> &bull;
                In 2005 I was building <a href="/img/unknownproject.info-2006.png" rel="noopener noreferrer" target="_blank">webhoster administration software in PHP and a mathematical graph plotting program</a> &bull;
                In 2007 I was contributing to a <a href="/img/wowwow-2006.png" rel="noopener noreferrer" target="_blank">open source World of Warcraft&trade; server emulator in C#</a> (in hindsight, I think it was pretty illegal since Blizzard shut it down a few years later) &bull;
                One year later I started the company <a href="/img/motokomedia.com-2010.png" rel="noopener noreferrer" target="_blank">Motoko Media</a> with a colleague for building web applications using PHP &bull;
                After I finished college I joined <a href="https://www.avisi.nl/" rel="noopener noreferrer" target="_blank">Avisi</a> where I became a Team Lead with an emphasis on Digital Identity and Identity & Access Management projects &bull;
                Then after 9 years I decided to venture out on my own, becoming a freelance consultant.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutMe;
