import React, {Component, PureComponent} from 'react';
import * as rssParser from 'react-native-rss-parser';
import './BlogPage.scss';
import * as moment from 'moment/moment';
import ExternalLink from '../ExternalLink';


class BlogPost extends PureComponent {
    render() {
        return (<div className="col-4 my-4">
                <div className="card blog">
                    <div className="card-body">
                        <h5 className="card-title"><ExternalLink
                            href={this.props.link}>{this.props.title}</ExternalLink></h5>
                        <div className="text-muted">{moment(this.props.date).format('LL')}</div>
                        <div className="card-text">{this.props.content}</div>
                        <div className="card-text footer"><ExternalLink href={this.props.link}>Read blog
                            post</ExternalLink></div>
                    </div>
                </div>
            </div>);
    }
}

class BlogPage extends Component {

    state = {posts: []};

    componentDidMount() {
        fetch('/blog/feed.xml')
            .then((response) => response.text())
            .then((responseData) => rssParser.parse(responseData))
            .then((rss) => {
                this.setState({posts: rss.items});
            });
    }

    render() {
        console.log(this.state.posts)
        return <div className="row d-flex justify-content-around">
            {this.state.posts.map(post => <BlogPost key={post.links[0].url} title={post.title} content={post.description}
                                                    date={post.published} link={post.links[0].url}/>)}
        </div>;
    }
}

export default BlogPage;
