import React, {PureComponent} from 'react';
import './Footer.scss';
import {EmailListLink} from "../ContactData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class Footer extends PureComponent {

  render() {
    // TODO!
    return <div id="about">
      <div className="container py-4">
        <div className="row d-flex justify-content-around">
          <div className="col-5">
            <div className="my-2">KvK: 75682605</div>
          </div>
          <div className="col-5">
            <ul className="fa-ul my-0">
              <li className="my-1"><span className="fa-li"><FontAwesomeIcon icon="map-marker-alt"/></span>Arnhem, Netherlands</li>
              <li className="my-1"><EmailListLink/></li>
            </ul>
          </div>
        </div>
      </div>
    </div>;
  }
}

export default Footer;
