import React, {PureComponent} from "react";

export default class Badge extends PureComponent {
  render() {
    return <span className={'badge ' + this.getClass()}>{this.props.children}</span>;
  }

  getClass() {
    switch (this.props.type ? this.props.type.toLowerCase() : 'unknown') {
      case "framework":
        return 'badge-secondary';
      case "protocol":
        return 'badge-success';
      case "database":
        return 'badge-danger';
      default:
      case 'language':
        return 'badge-primary'
    }
  }
}

export class BadgeList extends PureComponent {
  render() {
    return <span style={{display: 'block', textAlign: 'center'}}>{this.props.type ? React.Children.map(this.props.children, child => React.cloneElement(child, { type: this.props.type })) : this.props.children}</span>;
  }
}
