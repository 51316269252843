import React, { Component, PureComponent } from 'react';
import Badge, { BadgeList } from '../Badge';
import './ServicesPage.scss';


class Service extends PureComponent {
    render() {
        return <div className="card service my-4" style={{minWidth: '300px'}}>
            <div className="card-body">
                <h5 className="card-title">{this.props.title}</h5>
                <div className="card-text">{this.props.children}</div>
            </div>
        </div>;
    }
}


class ServicesPage extends Component {
    render() {
        return (
            <>
                <div className="card-deck">
                    <Service title="Decentralized Identity & Access Management">
                        <BadgeList>
                            <Badge>Self-Sovereign Identity</Badge>
                            <Badge>Verifiable Credentials</Badge>
                            <Badge>Decentralized Identifiers</Badge>
                        </BadgeList>
                        <p>Self-sovereign identity systems are on the rise. I can help out explaining and implementing them.</p>
                    </Service>
                    <Service title="Expert Software Engineering">
                        <p>
                            <BadgeList>
                                <Badge>Golang</Badge>
                                <Badge>Java</Badge>
                                <Badge>Kotlin</Badge>
                                <Badge>Spring Boot</Badge>
                                <Badge>Micronaut</Badge>
                                <Badge>Java EE</Badge>
                                <Badge>REST</Badge>
                                <Badge>PostgreSQL</Badge>
                                <Badge>Oracle</Badge>
                                <Badge>MongoDB</Badge>
                            </BadgeList>
                        </p>
                        <p>Do you need senior engineering on board? I can help out.</p>
                    </Service>
                    <Service title="System Architecture & Design">
                        <BadgeList>
                            <Badge>Emergent Design</Badge>
                            <Badge>C4</Badge>
                            <Badge>UML</Badge>
                        </BadgeList>
                        A clean architecture is essential to build maintainable, reliable software.
                        Having over +10 years of experience designing high-security systems in Agile environments, I can assess existing architectures or design greenfield systems
                        from the ground up.
                        I don't believe in ivory tower architecting; design and implementation should happen concurrently in one multidisciplinary team.
                    </Service>
                    <Service title="Tech Lead">
                        <BadgeList>
                            <Badge>Delivery</Badge>
                            <Badge>Future-proof software</Badge>
                            <Badge>Quality</Badge>
                        </BadgeList>
                        As a Tech Lead I can help you and your teams to:
                        <ul>
                            <li>Make the right technological choices</li>
                            <li>Delivering future-proof software</li>
                            <li>Raise the bar, raising the quality standard through on-the-job coaching.</li>
                        </ul>
                    </Service>
                </div>
            </>
        );
    }
}

export default ServicesPage;
