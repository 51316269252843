import { library } from '@fortawesome/fontawesome-svg-core';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCircle, faExternalLinkAlt, faMapMarkerAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ExternalLink from './components/ExternalLink';
import Footer from './components/pages/Footer';
import BlogPage from './components/pages/BlogPage';

import ContactForm from './components/pages/ContactForm';
import AboutMe from './components/pages/AboutMe';
import HomePage from './components/pages/HomePage';
import PortfolioPage from './components/pages/PortfolioPage';
import ServicesPage from './components/pages/ServicesPage';

import './index.scss';

library.add(faExternalLinkAlt, faSignOutAlt, faMapMarkerAlt, faCircle);


class PageContainer extends Component {
  render() {
    const name = this.props.title.toLowerCase();
    return (
        <div className="page" id={name}>
          <div className="header">
            <h1 className="display-4">{this.props.title}</h1>
          </div>
          <div className="container">
            {this.props.children}
          </div>
        </div>);
  };
}


class App extends Component {
  render() {
    return (
        <>
          <main className="flex-shrink-0" role="main">
            <div className="fixed-top">
              <div className="topbar">
                <nav className="navbar navbar-expand-lg navbar-dark container">
                  <a href="#start" className="nav-link navbar-brand">Rein Krul</a>
                  <ul className="navbar-nav">
                    {/*<li className="nav-item contact">*/}
                    {/*    <a href="#" data-toggle="modal" data-target="#contactForm" className="nav-link">Contact</a>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                      <ExternalLink href="https://reinkrul.nl/blog/" className="nav-link">
                        Blog
                      </ExternalLink>
                    </li>
                    <li className="nav-item">
                      <a href="#services" className="nav-link">Services</a>
                    </li>
                    <li className="nav-item">
                      <a href="#portfolio" className="nav-link">Portfolio</a>
                    </li>
                    <li className="nav-item">
                      <ExternalLink href="https://www.linkedin.com/in/reinkrul/" className="nav-link">
                        <FontAwesomeIcon icon={faLinkedin}/>
                        LinkedIn
                      </ExternalLink>
                    </li>
                    <li className="nav-item">
                      <ExternalLink href="https://github.com/reinkrul/" className="nav-link">
                        <FontAwesomeIcon icon={faGithub}/>
                        GitHub
                      </ExternalLink>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="spacer"></div>
            </div>
            <HomePage/>
            <PageContainer title="Services">
              <ServicesPage/>
            </PageContainer>
            <PageContainer title="Portfolio">
              <PortfolioPage/>
            </PageContainer>
            <PageContainer title="Blog">
              <BlogPage/>
            </PageContainer>
            <Footer/>
            <AboutMe/>
            <ContactForm/>
          </main>
        </>
    );
  }
}


export default App;

