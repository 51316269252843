import React, { Component, PureComponent } from 'react';
import ExternalLink from '../ExternalLink';
import './PortfolioPage.scss';


class PortfolioPage extends Component {
    render() {
        const cqrsLightUrl = 'https://github.com/reinkrul/cqrslight-micronaut';
        return (
            <>
                <div className="card-deck">
                    <Showcase title="Nuts Node" tech={['Golang', 'Verifiable Credentials', 'OpenID Connect', 'OAuth2']} href="https://github.com/nuts-foundation/nuts-node">
                        <p>
                            <ExternalLink href="https://github.com/nuts-foundation/nuts-node">Nuts Node</ExternalLink> is an Self-Sovereign Identity wallet,
                            Verifiable Credential issuer and verifier, and OpenID Connect / OAuth2 provider for exchanging healthcare information in a decentralized manner.
                            I'm one of the writers of the Nuts specification and core contributor to the reference implementation.
                        </p>
                    </Showcase>
                    <Showcase title="Orca" tech={['Golang', 'Verifiable Credentials', 'OpenID Connect', 'HL7 FHIR']} href="https://github.com/santeonnl/orca">
                        <p>
                            <ExternalLink href="https://github.com/santeonnl/orca">Orca</ExternalLink> is a Nuts solution architecture and reference implementation
                            for exchanging healthcare information according to IHE Dynamic Care Planning. I'm one of the core contributors.
                        </p>
                    </Showcase>
                    <Showcase title="Java DID Resolvers" tech={['Java', 'Decentralized Identifiers']} href="https://github.com/reinkrul/java-did-resolvers">
                        <p>
                            <ExternalLink href="https://github.com/reinkrul/java-did-resolvers">Java DID Resolvers</ExternalLink>
                            is a set of Java libraries for resolving <code>did:web</code> and <code>did:jwk</code> DID documents (Decentralized Identifiers).
                        </p>
                    </Showcase>
                    <Showcase title="CQRS Light for Micronaut" tech={['CQRS', 'Java', 'Micronaut', 'ReactJS']} href={cqrsLightUrl}>
                        <p>
                            <ExternalLink href={cqrsLightUrl}>CQRS Light</ExternalLink> is a lightweight <ExternalLink
                            href="https://martinfowler.com/bliki/CQRS.html">CQRS</ExternalLink> library
                            for Micronaut.
                            It allows the use of CQRS without having to introduce a heavyweight framework which might be impossible.
                        </p>
                        <p>
                            It helps you to encapsulate commands and queries leading to more cohesion and less coupling, which in turns leads to simpler and more maintainable code.
                            There's a
                            Javascript component for easy front-end integration.
                        </p>
                    </Showcase>
                    <Showcase title="Kotlin Webtest" tech={['Kotlin', 'DSL', 'SOAP', 'REST']} href="https://github.com/avisi/kotlin-webtest">
                        <p>
                            Most of us who had to test SOAP or REST web services came across tooling like SoapUI or Postman but didn't like it. The problem? These tools are
                            UI-centric
                            which often leads to unmaintainable test suites. <ExternalLink href="https://github.com/avisi/kotlin-webtest">Kotlin Webtest</ExternalLink> solves this
                            by
                            enabling a developer/tester to write their SOAP or REST tests in a Kotlin DSL, straight from your favorite IDE.
                        </p>
                        <p>Also see the <ExternalLink href="https://reinkrul.nl/blog/kotlin/webservice/testing/soap/rest/dsl/2018/04/01/avisi-kotlin-webservice-webtest.html">corresponding
                            blog
                            post</ExternalLink>.</p>
                    </Showcase>
                    <Showcase title="Quarkus CA" tech={['Java', 'Quarkus', 'X.509', 'GraalVM']} href="https://github.com/reinkrul/quarkus-ca">
                        <p>
                            <ExternalLink href="https://github.com/reinkrul/quarkus-ca">Quarkus CA</ExternalLink> is an educational project teaching the basics of a Certificate
                            Authority
                            issuing X.509 certificates. At the same time it's an experiment with the (at the time of writing) brand new <ExternalLink
                            href="https://quarkus.io/">Quarkus</ExternalLink> application framework, a
                            strong new rival of Micronaut.
                        </p>
                        <p>Also see the <ExternalLink href="https://reinkrul.nl/blog/quarkus/graalvm/ca/pki/2019/06/12/quarkus-graalvm-ca.html">corresponding blog post</ExternalLink>.</p>
                    </Showcase>
                    <Showcase title="JMS Gateway" tech={['Kotlin', 'JMS', 'Java EE']} href="https://github.com/reinkrul/jms-gateway">
                        The Java Message Service (JMS) API is complex, especially when used with distributed transactions (2 phase commits), which are downright unreliable.
                        This is where <ExternalLink href="https://github.com/reinkrul/jms-gateway">JMS Gateway</ExternalLink> comes in: you simply write your JMS messages to a SQL
                        database
                        and JMS Gateway takes care of reliably sending it to the remote message broker. This avoids unreliable 2-phase commits (transactions spanning multiple
                        data sources)
                        since your messaging outbox shares the same database as your application.
                    </Showcase>
                    <Showcase title="MongoMigrate" href="https://github.com/reinkrul/mongo-migrate">
                        <ExternalLink href="https://github.com/reinkrul/mongo-migrate">MongoMigrate</ExternalLink> is a Continuous Deployment tool for automating MongoDB schema
                        migrations using Java. It is a perfect fit for microservice frameworks like Spring Boot or Micronaut.
                    </Showcase>

                    <Showcase title="SailorsTab.NET" tech={['C#', 'Mono', 'GTK']} href="https://github.com/reinkrul/SailorsTabDotNet">
                        A <ExternalLink href="https://github.com/reinkrul/SailorsTabDotNet">bar-tab-keeping application</ExternalLink> for a non-profit, yachting-themed bar I used
                        to hang out in (not a joke). Demonstrates how to build a cross platform C# GTK UI application using Mono.
                    </Showcase>
                </div>
            </>
        );
    }
}


class Showcase extends Component {
    render() {
        const tech = (this.props.tech ? this.props.tech : []).map((t) => <Tech key={this.props.title + t}>{t}</Tech>);
        const title = this.props.href ? <ExternalLink href={this.props.href} className="showcase-title">{this.props.title}</ExternalLink> : this.props.title;
        return <div className="card showcase my-4" style={{minWidth: '300px'}}>
                <div className="card-body">
                    <h5 className="card-title">{this.props.title}</h5>
                    <p className="card-text tech">{tech}</p>
                    <div className="card-text">{this.props.children}</div>
                </div>
            </div>;
    }
}


class Tech extends PureComponent {
    render() {
        return <span className="badge badge-primary">{this.props.children}</span>;
    }
}


export default PortfolioPage;
